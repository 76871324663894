import React, { memo, useCallback, useEffect, useState } from "react"
import { Box, Center, Flex, Heading, Text } from "@chakra-ui/react"

import { withSection } from "@app/hoc/Section"

import type { Section } from "@root/types/global"
import { useRoutes } from "@app/hooks/useRoutes"
import { Link } from "@app/components/Link"
import { useSanityColour } from "@app/hooks/useSanity"
type Props = Section & GatsbyTypes.SanitySectionSalesBannerTimer

type TimeLeft = {
  hours: string
  minutes: string
  seconds: string
  marginBottom: number
  marginTop: number
}

const SalesBannerTimer: React.FC<Props> = withSection(
  memo(({ title, link, themeColour, endDate, hoursLabel, minutesLabel, secondsLabel, tag, marginBottom = 0, marginTop = 0 }) => {
    const { urlResolver } = useRoutes()
    const resolvedLink = urlResolver(link)
    const showTimer = endDate && new Date() < new Date(endDate)
    const { background, colour } = useSanityColour(themeColour, {
      background: themeColour?.themeColourBackground?.theme || "grey.coal",
      colour: themeColour?.themeColourText?.theme || "white",
    })

    return (
      <Center
        as="section"
        bgColor={background}
        color={colour}
        mt={marginTop ? `${marginTop}px` : 0}
        mb={marginBottom ? `${marginBottom}px` : 0}
      >
        <Flex
          maxW="container.lg"
          flexDirection={{ base: "column", md: "row" }}
          textAlign="center"
          justifyContent={showTimer ? "space-between" : "center"}
          alignItems="center"
          py={5}
          gap={6}
          px={{ base: 8, md: 28 }}
          w="100%"
        >
          {showTimer && (
            <CountdownTimer endDate={endDate} hoursLabel={hoursLabel} minutesLabel={minutesLabel} secondsLabel={secondsLabel} />
          )}
          <Flex>
            <Heading
              as={tag || "h2"}
              fontWeight={500}
              letterSpacing="0px"
              fontSize="32px"
              lineHeight="32px"
              mt="6px"
              textTransform="capitalize"
            >
              {title}
            </Heading>
          </Flex>

          <Flex as={Link} to={resolvedLink?.url}>
            <Text
              textTransform="uppercase"
              textDecoration="underline"
              size="sm"
              fontWeight={700}
              lineHeight="16px"
              letterSpacing="2.8px"
              mt="6px"
            >
              {resolvedLink?.title}
            </Text>
          </Flex>
        </Flex>
      </Center>
    )
  })
)

const CountdownTimer: React.FC<{ endDate: string; hoursLabel: string; minutesLabel: string; secondsLabel: string }> = memo(
  ({ endDate, hoursLabel, minutesLabel, secondsLabel }) => {
    const calculateTimeLeft: () => TimeLeft = useCallback(() => {
      const difference = +new Date(endDate) - +new Date()
      const timeLeft: TimeLeft = {
        hours: "00",
        minutes: "00",
        seconds: "00",
      }

      if (difference > 0) {
        timeLeft.hours = Math.floor(difference / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0")
        timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0")
        timeLeft.seconds = Math.floor((difference / 1000) % 60)
          .toString()
          .padStart(2, "0")
      }

      return timeLeft
    }, [endDate])

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
      let timer: NodeJS.Timeout
      if (new Date() < new Date(endDate)) {
        timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft())
        }, 1000)
      }
      return () => clearInterval(timer)
    }, [calculateTimeLeft, endDate])

    return (
      <Flex gap={4}>
        <Box>
          <Text fontWeight={500} fontSize="24px" lineHeight="32px">
            {timeLeft.hours}
          </Text>
          <Text fontWeight={500} size="xs" lineHeight="16px">
            {hoursLabel}
          </Text>
        </Box>
        <Text fontWeight={500} fontSize="24px" lineHeight="32px" mt={-0.75}>
          :
        </Text>
        <Box>
          <Text fontWeight={500} fontSize="24px" lineHeight="32px">
            {timeLeft.minutes}
          </Text>
          <Text fontWeight={500} size="xs" lineHeight="16px">
            {minutesLabel}
          </Text>
        </Box>
        <Text fontWeight={500} fontSize="24px" mt={-0.75} lineHeight="32px">
          :
        </Text>
        <Box>
          <Text fontWeight={500} fontSize="24px" lineHeight="32px">
            {timeLeft.seconds}
          </Text>
          <Text fontWeight={500} size="xs" lineHeight="16px">
            {secondsLabel}
          </Text>
        </Box>
      </Flex>
    )
  }
)

export default SalesBannerTimer
